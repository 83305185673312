const AUTO_BID = {
	name: '自动竞价',
	value: 'AUTO_BID',
	needValue: false
}
const LOWEST_COST_WITH_MAX_BID = {
	name: '最高出价',
	value: 'LOWEST_COST_WITH_MAX_BID',
	needValue: true
}
const TARGET_COST = {
	name: '目标出价',
	value: 'TARGET_COST',
	needValue: true
}

const BID_TYPE_CUSTOM = {
	name: '成本上限',
	value: 'BID_TYPE_CUSTOM',
	needValue: true
}

const BID_TYPE_NO_BID = {
	name: '最低成本',
	value: 'BID_TYPE_NO_BID',
	needValue: false
}
const VO_HIGHEST_VALUE = {
	name: '最高价值',
	value: 'VO_HIGHEST_VALUE',
	needValue: false
}
const VO_MIN_ROAS = {
	name: 'ROAS下限',
	value: 'VO_MIN_ROAS',
	needValue: true
}

// 优化目标
export const optimizationGoalTypes = {
	'snapchat': [{
			name: '像素购买',
			value: 'PIXEL_PURCHASE',
			bidModelType: [{...AUTO_BID}, {...LOWEST_COST_WITH_MAX_BID}, {...TARGET_COST}]
		},
		{
			name: '像素注册',
			value: 'PIXEL_SIGNUP',
			bidModelType: [{...AUTO_BID}, {...LOWEST_COST_WITH_MAX_BID}, {...TARGET_COST}]
		},
		{
			name: '像素添加购物车',
			value: 'PIXEL_ADD_TO_CART',
			bidModelType: [{...AUTO_BID}, {...LOWEST_COST_WITH_MAX_BID}, {...TARGET_COST}]
		}
	],
	'tiktok': [{
			name: '转化',
			value: 'CONVERT',
			bidModelType: [{...BID_TYPE_CUSTOM}, {...BID_TYPE_NO_BID}]
		},
		{
			name: '最大价值',
			value: 'VALUE',
			bidModelType: [{...VO_MIN_ROAS},{...VO_HIGHEST_VALUE}]
		}
	]


}